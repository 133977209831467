.about-hero {
    width: 100%;
    padding: 50px 0 0 0;
    position: relative;
    z-index: -1;
  
    .round-blur {
      position: absolute;
      right: 20px;
      bottom: 10px;
      z-index: 2;
      width: 280px;
      height: 280px;
  
      @media (max-width: 1387px) {
        right: 20px;
        bottom: 250px;
      }
      @media (max-width: 1200px) {
        width: 100px;
        height: 100px;
        left: 200px;
        top: 20px;
        display: block;
      }
  
      @media (max-width: 900px) {
        width: 100px;
        height: 100px;
        left: 200px;
        top: 20px;
        display: block;
      }
  
      @media (max-width: 600px) {
        width: 100px;
        height: 100px;
        left: 200px;
        top: 20px;
        display: block;
      }
    }
  
    .round-blur-med {
      position: absolute;
      left: 20px;
      top: 200px;
      z-index: 2;
      width: 200px;
      height: 200px;
  
      @media (max-width: 1387px) {
        display: none;
      }
  
      @media (max-width: 1200px) {
        display: none;
      }
  
      @media (max-width: 900px) {
        display: none;
      }
  
      @media (max-width: 600px) {
        display: none;
      }
    }
  
    .round-blue-large {
      position: absolute;
      right: -30px;
      top: -95px;
      z-index: 2;
      display: block;
  
      @media (max-width: 1387px) {
        display: none;
      }
  
      @media (max-width: 1200px) {
        width: 100px;
        height: 100px;
        right: 190px;
        top: -30px;
        display: block;
      }
      @media (max-width: 900px) {
        width: 100px;
        height: 100px;
        right: 190px;
        top: -30px;
        display: block;
      }
      @media (max-width: 600px) {
        width: 100px;
        height: 100px;
        right: 190px;
        top: -30px;
        display: block;
      }
    }
  
    .round-blue-small {
      position: absolute;
      left: 20px;
      top: 10px;
      z-index: 2;
  
      @media (max-width: 1200px) {
        display: none;
      }
  
      @media (max-width: 900px) {
        display: none;
      }
  
      @media (max-width: 600px) {
        display: none;
      }
    }
  
    .blue-cookie {
      position: absolute;
      right: 200px;
      top: 140px;
      z-index: 2;
  
      @media (max-width: 1200px) {
        display: none;
      }
  
      @media (max-width: 900px) {
        display: none;
      }
  
      @media (max-width: 600px) {
        display: none;
      }
    }
  
    .hero-skew {
      background: linear-gradient(
        180deg,
        rgba(249, 249, 249, 0.29) -14.99%,
        rgba(85, 202, 255, 0.52) 118.32%
      );
      width: 100% !important;
      height: 100% !important;
      position: absolute;
      top: 0;
      padding: 40px;
      z-index: -1;
  
      @media (max-width: 1200px) {
      }
  
      @media (max-width: 900px) {
      }
  
      @media (max-width: 600px) {
      }
    }
  
    .wrapper {
      width: 85%;
      margin: 0 auto;
      display: flex;
  
      @media (max-width: 1387px) {
        width: 90%;
      }
  
      @media (max-width: 1315px) {
        width: 95%;
      }
  
      @media screen and (max-width: 1200px) {
      }
      @media screen and (max-width: 900px) {
        display: inline-block;
      }
  
      .left {
        width: 60%;
        padding-top: 100px;
        color: var(--head-txt);
        position: relative;
  
        @media screen and (max-width: 1200px) {
          width: 100%;
        }
  
        @media screen and (max-width: 900px) {
        }
  
        @media screen and (max-width: 600px) {
          padding-top: 60px;
        }
  
        span {
          a {
            font-family: Poppins-Bold, sans-serif;
            text-decoration: underline;
            color: var(--btn-color) !important;
            font-size: 20px;
            display: inline-block !important;
            padding-left: 5px;
          }
        }
  
        h2 {
          animation-name: fadeInDown;
          animation-duration: 2s;
        }
        p {
          animation-name: fadeInRight;
          animation-duration: 2s;
        }
        a {
          animation-name: fadeInUp;
          animation-duration: 2s;
        }
  
        h2 {
          font-size: 72px;
          font-style: normal;
          line-height: 75px;
          max-width: 570px !important;
          letter-spacing: 1px;
          font-weight: 700;
  
          @media (max-width: 1387px) {
            font-size: 70px;
            font-style: normal;
            line-height: 88px;
            max-width: 600px;
            letter-spacing: 1px;
            font-weight: 700;
          }
  
          @media screen and (max-width: 1200px) {
            max-width: 750px;
            font-size: 68px;
            font-style: normal;
            line-height: 80px;
            font-weight: 700;
          }
  
          @media screen and (max-width: 900px) {
            max-width: 750px;
            font-size: 60px;
            font-style: normal;
            line-height: 65px;
            font-weight: 700;
          }
  
          @media screen and (max-width: 700px) {
            max-width: 300px;
            font-size: 40px;
            font-style: normal;
            line-height: 50px;
            font-weight: 700;
            padding: 0px 20px;

          }
        }
  
        p {
          max-width: 700px !important;
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 30px;
          color: var(--text-sec);
          letter-spacing: 1px;
          margin: 20px 0 70px 0;
          // opacity: 0.7;

          @media screen and (max-width:900px) {
            padding: 0px 15px
          }
        }
      }
  
      .right {
        flex: 1;
        height: 100%;
  
        @media screen and (max-width: 1200px) {
          width: 100%;
        }
  
        img {
          width: 76%;
          height: 100%;
          margin-top: 20px;
          animation-name: fadeInDown;
          animation-duration: 2s;
  
          @media (max-width: 1387px) {
            width: 96%;
          }
  
          @media (max-width: 1315px) {
            width: 100%;
          }
  
          @media (max-width: 1264px) {
            display: inline-block;
          }
  
          @media screen and (max-width: 900px) {
            max-height: 400px;
          }
        }
      }
      // }
    }
  
    .privacy-container {
      position: absolute;
      margin-left: -32.5%;
      left: 50%;
      background-color: var(--white);
      padding: 40px;
  
      @media (max-width: 1387px) {
        // width: 90%;
        margin-left: -45%;
      }
  
      @media (max-width: 1315px) {
        margin-left: -47.5%;
      }
  
      h3 {
        font-style: normal;
        font-weight: 900;
        font-size: 28px;
        line-height: 35px;
      }
      h5 {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
      }
      h6 {
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
      }
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }
      span {
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
      }
  
      // @media screen and (max-width: 1200px) {
      //   grid-template-columns: repeat(3, 1fr);
      // }
      // @media screen and (max-width: 900px) {
      //   grid-template-columns: repeat(2, 1fr);
      // }
      @media screen and (max-width: 600px) {
        padding: 10px;
      }
    }
  }
  