.career-content {
  width: 100%;
  padding: 0px 60px;
  // position: relative;

  .career-container {
    margin-top: -10%;
    width: 1000px;
    margin: 3% auto 10% auto;
    // display: flex;

    @media (max-width: 1387px) {
      width: 90%;
    }

    @media (max-width: 1315px) {
      width: 95%;
    }
    @media (max-width: 900px) {
      width: 300px;
      margin: 10% auto;
    }

    .accordion-wrapper {
        margin: 6% 0px;
        
        h3 {
          color: var(--head-txt);
          margin: 0px 0px 30px 0;
          font-size: 25px;
        }
      }
  }

  h2 {
    font-size: 72px;
    color: var(--head-txt);
    line-height: 105%;

    @media screen and(max-width:900px) {
      font-size: 25px;
    }
  }
  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    margin: 20px auto;
    opacity: 0.699999988079071;
    color: var(--head-txt);

    @media (max-width: 900px) {
      font-size: 16px;
      line-height: 28px;
    }

   
  }
}
