.about-section {
  width: 100%;
  padding: 0px 60px;
  @media screen and(max-width: 900px) {
    margin: 0 auto;
    padding: 0px 20px;
  }

  .wrapper {
    width: 100%;
    margin: 0 auto;
    display: flex;

    @media (max-width: 1387px) {
      width: 90%;
    }

    @media (max-width: 1315px) {
      width: 95%;
    }

    @media screen and (max-width: 1200px) {
    }
    @media screen and (max-width: 900px) {
      display: inline-block;
    }

    .left {
      width: 50%;
      padding-top: 100px;
      position: relative;

      @media screen and (max-width: 900px) {
        margin: 0 auto;
      }

      img {
        width: auto;
        height: 70%;
        margin: 0 auto;
        animation-name: fadeInRight;
          animation-duration: 2s;


        @media screen and (max-width: 900px) {
          width: 90%;
          margin: 0 auto;
        }
      }
    }
    .right {
      width: 50%;
      padding-top: 100px;
      margin: 0 auto;

      @media screen and (max-width: 1200px) {
        width: 100%;
      }
      @media screen and (max-width: 900px) {
        width: 100%;
        padding: 0px 20px;
        margin: 10px auto;
      }

      h3 {
        font-size: 72px;
        font-weight: bold;
        line-height: 105%;
        color: #093867;

        @media screen and (max-width: 900px) {
          font-size: 25px;
        }
      }
      p {
        color: #093867;
        font-size: 18px;
        font-weight: 400;
        line-height: 25px;
        margin-top: 20px;
        opacity: 0.699999988079071;

        @media screen and(max-width:900px) {
          font-size: 16px;
          line-height: 30px;
        }
      }
      img {
        width: 10%;

        @media screen and (max-width: 900px) {
          width: 20%;
        }
      }
      ul > li {
        list-style-type: disc;
        color: #093867;
        font-size: 18px;
        opacity: 0.699999988079071;
        line-height: 25px;

        @media screen and(max-width: 900px) {
          font-size: 16px;
          line-height: 30px;
        }
      }
      .vision-margin {
        margin-top: 40px;
      }
    }
  }
  .stru-wrapper {
    margin-top: -10%;
    width: 1000px;
    margin: 3% auto 10% auto;

    @media screen and(max-width: 900px) {
      width: 300px;
      margin: 10% auto;
    }

    h3 {
      color: #093867;
      font-size: 72px;

      @media screen and(max-width:900px) {
        font-size: 25px;
      }
    }
    p {
      color: #093867;
      font-size: 18px;
      line-height: 25px;
      opacity: 0.699999988079071;

      @media screen and(max-width:900px) {
        font-size: 16px;
        line-height: 30px;
      }
    }
  }

  .hierachy{
    width: 100%;
    margin: 0 auto;

    @media screen and(max-width:900px) {
      margin: 0 auto;
      width: 90%;
    }

    img{
      width: 600px;
      height: 100%;
      margin: 0 auto 10% auto;

      @media screen and(max-width: 900px) {
        width: 200px;
        height: 100%;
      }
    }
  }
}
