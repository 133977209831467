.header {
  width: 100%;
  height: 70px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 300;
  // background-color: aqua;
  background: transparent;
  transition: background 0.4s ease-in-out;

  &.bgDark {
    background: rgba(0, 0, 0, 0.9);
  }
  &.bgLight {
    background-color: var(--white);
  }
  .wrapper {
    width: 85%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    height: 100%;
    max-width: var(--wrapper-max-width);

    @media (max-width: 1387px) {
      width: 90%;
    }

    @media (max-width: 1315px) {
      width: 95%;
    }

    @media (max-width: 1264px) {
      justify-content: space-between;
    }

    .logo {
      width: 200px;
      height: fit-content;
      // margin-bottom: 7px;

      @media (max-width: 1264px) {
        // margin-bottom: 0px;
      }
      img {
        width: 45px;
        height: 45px;
      }
      // > a {
      //   width: 102px;
      //   color: var(--dark);
      //   font-weight: bolder;
      //   font-size: 25px;
      // }
    }

    .hamburgerBtn {
      display: none;

      @media (max-width: 1264px) {
        display: block;
      }
    }
  }
}
