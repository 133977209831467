.for-animation {
    width: 100%;
    height: 405px;
    background-color: #ecececad;
    /* background-color: #f1f1f1; */
    border-radius: 2px;
    z-index: 999 !important;
    position: fixed;
    right: 4rem;
    bottom: 0rem;
  }
  .for-animation-portal {
    width: 650px;
    height: 405px;
    background-color: #ecececad;
    /* background-color: #f1f1f1; */
    border-radius: 2px;
    z-index: 999 !important;
    position: fixed;
    right: 10rem;
    bottom: 24rem;
  }
  .for-animation-portal img {
    width: 100%;
  }
  .for-animation img {
    /* width: 300px; */
  }
  .bgLight {
    background-color: #fff;
  }
  .bgTransparent {
    background-color: transparent;
  }
  
  .showw {
    visibility: visible;
    transition: all linear 0.5s;
    transform: translateY(0%);
  }
  
  .hide {
    visibility: hidden;
    transform: translateY(100%);
    transition: all linear 0.5s;
    padding: 0.8rem 1.2rem 0.6rem 1.2rem;
  }
  .showw-portal {
    visibility: visible;
  
    transition: all linear 0.5s;
    transform: translateX(20%);
  }
  
  .hide-portal {
    visibility: hidden;
    transform: translateX(-100%);
    transition: all linear 0.5s;
    padding: 0.8rem 1.2rem 0.6rem 1.2rem;
  }
  .close-animation {
    background-color: #fff;
    border-radius: 50%;
    padding: 10px;
    right: -22px;
    top: -22px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  }
  .close-animation img {
    width: 15px;
  }
  
  .boyanime {
    position: absolute;
    width: 100%;
    /* top: 40%; */
  }
  .quotelms {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 350px !important;
  }
  
  .quotelms-portal {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 350px !important;
  }
  .cloud-txt {
    font-weight: bolder;
    width: 240px;
    position: absolute;
    top: 80px;
    right: 60px;
    font-size: 18px;
  }
  .cloud-txt a {
    color: #00c853;
  }
  .cloud-txt span {
    margin: 0px 5px;
  }
  .cloud-txt a:hover {
    text-decoration: underline;
  }
  
  .cloud-txt-portal {
    font-weight: bolder;
    position: absolute;
    top: 70px;
    right: 55px;
  }
  .cloud-txt-portal h4 {
    width: 150px;
    font-size: 20px;
  }
  
  .cloud-txt-portal h4 {
    display: block;
    margin-block-start: 0.73em;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }
  
  .cloud-txt-portal p {
    width: 240px;
    font-size: 16px;
  }
  
  .cloud-txt-portal a {
    color: #00c853;
  }
  .cloud-txt-portal span {
    margin: 0px 5px;
  }
  .cloud-txt-portal a:hover {
    text-decoration: underline;
  }
  
  @media screen and (max-width:500px) {
    .for-animation-portal {
      display: flex;
      justify-content: center;
      width: 340px;
      height: 405px;
      right: 5.5rem;
      bottom: 10rem;
    }
    .for-animation-portal img {
      width: 100%;
    }
  }