.prod-hero {
  width: 100%;
  height: 100%;
  padding: 50px 0 140px 0;
  position: relative;

  .round-blur {
    position: absolute;
    right: 400px;
    top: 160px;
    z-index: 2;
    width: 280px;
    height: 280px;

    @media (max-width: 1387px) {
      right: 400px;
      top: 100px;
    }
    @media (max-width: 1200px) {
      display: none;
    }
    @media (max-width: 900px) {
      display: none;
    }
    @media (max-width: 600px) {
      display: none;
    }
  }
  .round-blur2 {
    position: absolute;
    left: -10px;
    top: 210px;
    z-index: 2;
    width: 280px;
    height: 280px;
    display: block;

    @media (max-width: 1387px) {
      display: none;
    }

    @media (max-width: 1200px) {
      width: 100px;
      height: 100px;
      left: 200px;
      top: 20px;
      display: block;
    }

    @media (max-width: 900px) {
      width: 100px;
      height: 100px;
      left: 200px;
      top: 20px;
      display: block;
    }

    @media (max-width: 600px) {
      width: 100px;
      height: 100px;
      left: 200px;
      top: 20px;
      display: block;
    }
  }

  .round-blue-large {
    position: absolute;
    right: 150px;
    top: 105px;
    z-index: 2;

    @media (max-width: 1200px) {
      width: 100px;
      height: 100px;
      right: 190px;
      top: -30px;
    }
    @media (max-width: 900px) {
      width: 100px;
      height: 100px;
      right: 190px;
      top: -30px;
    }
    @media (max-width: 600px) {
      width: 100px;
      height: 100px;
      right: 190px;
      top: -30px;
    }
  }
  .round-blue-large2 {
    position: absolute;
    left: 20px;
    top: 10px;
    z-index: 2;

    @media (max-width: 1387px) {
      top: -30px;
    }
    @media (max-width: 1200px) {
      display: none;
    }

    @media (max-width: 900px) {
      display: none;
    }

    @media (max-width: 600px) {
      display: none;
    }
  }

  .round-blue-small {
    position: absolute;
    right: 700px;
    top: 10px;
    z-index: 2;

    @media (max-width: 1200px) {
      display: none;
    }

    @media (max-width: 900px) {
      display: none;
    }

    @media (max-width: 600px) {
      display: none;
    }
  }

  .magnet-blue {
    position: absolute;
    right: 400px;
    top: 110px;
    z-index: 2;

    @media (max-width: 1200px) {
      display: none;
    }

    @media (max-width: 900px) {
      display: none;
    }

    @media (max-width: 600px) {
      display: none;
    }
  }

  .hero-skew {
    background: linear-gradient(
      180deg,
      rgba(249, 249, 249, 0.29) -14.99%,
      rgba(85, 202, 255, 0.52) 118.32%
    );
    width: 100% !important;
    height: 100% !important;
    position: absolute;
    top: 0;
    padding: 40px;
  }

  .wrapper {
    width: 85%;
    margin: 0 auto;
    display: flex;
    height: 100%;

    @media (max-width: 1387px) {
      width: 90%;
    }

    @media (max-width: 1315px) {
      width: 95%;
    }

    .left {
      width: 60%;
      padding-top: 100px;
      color: var(--head-txt);
      position: relative;

      @media screen and (max-width: 1200px) {
        width: 100%;
      }

      @media screen and (max-width: 600px) {
        padding-top: 60px;
      }

      h2 {
        animation-name: fadeInDown;
        animation-duration: 2s;
      }
      p {
        animation-name: fadeInRight;
        animation-duration: 2s;
      }
      a {
        animation-name: fadeInUp;
        animation-duration: 2s;
      }

      h2 {
        font-size: 72px;
        font-style: normal;
        line-height: 75px;
        max-width: 570px !important;
        letter-spacing: 1px;
        font-weight: 700;

        @media (max-width: 1387px) {
          font-size: 70px;
          font-style: normal;
          line-height: 88px;
          max-width: 600px;
          letter-spacing: 1px;
          font-weight: 700;
        }

        @media screen and (max-width: 1200px) {
          max-width: 750px;
          font-size: 68px;
          font-style: normal;
          line-height: 80px;
          font-weight: 700;
        }

        @media screen and (max-width: 900px) {
          max-width: 750px;
          font-size: 60px;
          font-style: normal;
          line-height: 65px;
          font-weight: 700;
        }

        @media screen and (max-width: 700px) {
          max-width: 300px;
          font-size: 40px;
          font-style: normal;
          line-height: 50px;
          font-weight: 700;
        }
      }

      p {
        max-width: 700px !important;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        color: var(--text-sec);
        letter-spacing: 1px;
        margin: 20px 0 70px 0;
        opacity: 0.7;
      }
    }

    .right {
      flex: 1;
      height: 100%;

      @media screen and (max-width: 1200px) {
        width: 100%;
      }

      img {
        width: 76%;
        height: 100%;

        @media (max-width: 1387px) {
          width: 96%;
        }

        @media (max-width: 1315px) {
          width: 100%;
        }

        @media screen and (max-width: 900px) {
          max-height: 400px;
        }
      }
    }
    // }
  }

  .services-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    // gap: 43px;
    row-gap: 53px;
    column-gap: 30px;
    position: absolute;
    // margin-left: -32.5%;
    // left: 40%;
    padding: 0px 50px;

    @media (max-width: 1387px) {
      margin-left: -45%;
    }

    @media (max-width: 1315px) {
      margin-left: -47.5%;
    }

    @media screen and (max-width: 1200px) {
      // grid-template-columns: repeat(auto-fit, minmax(200px, 377px));
      grid-template-columns: repeat(3, 1fr);
    }
    @media screen and (max-width: 900px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 600px) {
      grid-template-columns: repeat(1, 1fr);
    }

    .service-card {
      background-color: var(--white);
      box-shadow: 0px 15px 26px rgba(1, 73, 159, 0.05);
      border-radius: 7px;
      padding: 30px;
      position: relative;
      margin:20px 0px ;

      &:hover {
        box-shadow: 0px 15px 26px rgba(1, 72, 159, 0.311);
        transition: all 0.3s linear;
        /* margin: -0.3rem -0.3rem 0 -0.3rem; */
        transform: scale(1.05);
        cursor: pointer;

        @media screen and (max-width: 600px) {
          transform: scale(1.0001);
        }
      }

      .gloss-icon {
        position: absolute;
        top: -50px;
        right: 0;
        width: 73px;
      }
      h3 {
        font-size: 30px;
        color: var(--head-txt);
        height: 80px;
      }
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 170%;
        opacity: 0.7;
        color: var(--pry-color);
        margin-bottom: 20px;
        height: 160px;
      }

      .read-more {
        color: var(--pry-color);
        text-decoration: underline;
        position: absolute;
        bottom: 30px;
        background-color: transparent;
      }
      .service-list-user{
        font-size: 15px !important;
        opacity: 1 !important;
        width: 250px;
      }
    }
    
  }
  
}

