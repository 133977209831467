.hero {
  width: 100%;
  padding: 50px 0 50px 0;
  position: relative;

  @keyframes bounce {
    0% {
      transform: translateY(-10px);
    }
    50% {
      transform: translateY(10px);
    }
    100% {
      transform: translateY(-10px);
    }
  }

  @media screen and (max-width: 1200px) {
    height: auto;
    padding: 200px 0px 0px;
  }
  @media screen and (max-width: 900px) {
    height: auto;
    padding: 150px 0px 0px;
  }
  @media screen and (max-width: 600px) {
    height: auto;
    padding: 100px 0px 0px;
  }

  .indicator {
    animation: bounce;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }

  .round-blur {
    position: absolute;
    right: 20px;
    bottom: 10px;
    z-index: 2;
    width: 280px;
    height: 280px;

    @media (max-width: 1200px) {
      width: 180px;
      height: 180px;
      right: -40px;
      bottom: -0px;
    }
    @media (max-width: 900px) {
      width: 180px;
      height: 180px;
      right: -40px;
      bottom: -0px;
    }
    @media (max-width: 605px) {
      right: -40px;
      bottom: -80px;
      width: 180px;
      height: 180px;
    }
  }
  .round-blur2 {
    position: absolute;
    left: -110px;
    bottom: 10px;
    z-index: 2;
    width: 280px;
    height: 280px;
    display: block;

    @media (max-width: 1387px) {
      display: none;
    }
    @media (max-width: 1200px) {
      left: 180px;
      bottom: 530px;
      width: 160px;
      height: 160px;
      display: block;
    }
    @media (max-width: 900px) {
      left: 120px;
      bottom: 310px;
      width: 160px;
      height: 160px;
      display: block;
    }
    @media (max-width: 605px) {
      left: 0px;
      bottom: 150px;
      width: 160px;
      height: 160px;
      display: block;
    }
  }
  .round-blur-med {
    position: absolute;
    left: 20px;
    top: 100px;
    z-index: 2;
    width: 200px;
    height: 200px;

    @media (max-width: 1387px) {
      display: none;
    }
    @media (max-width: 1200px) {
      left: -90px;
      bottom: 150px;
      width: 160px;
      height: 160px;
    }
    @media (max-width: 900px) {
      left: -90px;
      bottom: 150px;
      width: 160px;
      height: 160px;
    }
    @media (max-width: 605px) {
      left: -90px;
      bottom: 150px;
      width: 160px;
      height: 160px;
    }
  }
  .round-blur-med2 {
    position: absolute;
    right: 550px;
    top: 50px;
    z-index: 2;
    width: 200px;
    height: 200px;

    @media (max-width: 1200px) {
      display: none;
    }
    @media (max-width: 900px) {
      display: none;
    }
    @media (max-width: 605px) {
      display: none;
    }
  }

  .round-blue-large {
    position: absolute;
    right: -30px;
    top: -95px;
    z-index: 2;

    @media (max-width: 1387px) {
      display: none;
    }
    @media (max-width: 1200px) {
      display: none;
    }
    @media (max-width: 900px) {
      display: none;
    }
    @media (max-width: 605px) {
      display: none;
    }
  }
  .round-blue-large2 {
    position: absolute;
    left: 200px;
    bottom: 15px;
    z-index: 2;

    @media (max-width: 1200px) {
      left: 480px !important;
      top: 15px;
      width: 170px;
      height: 170px;
    }
    @media (max-width: 900px) {
      left: 480px !important;
      top: 15px;
      width: 170px;
      height: 170px;
    }
    @media (max-width: 605px) {
      left: 280px !important;
      top: 125px;
      width: 170px;
      height: 170px;
    }
  }

  .round-blue-small {
    position: absolute;
    left: 20px;
    top: 10px;
    z-index: 2;

    @media (max-width: 1200px) {
      width: 70px;
      height: 70px;
      left: 120px;
      top: -10px;
    }
    @media (max-width: 900px) {
      width: 70px;
      height: 70px;
      left: 120px;
      top: -10px;
    }
    @media (max-width: 605px) {
      width: 70px;
      height: 70px;
      left: 120px;
      top: -10px;
    }
  }
  .round-blue-small2 {
    position: absolute;
    left: 20px;
    bottom: 10px;
    z-index: 2;

    @media (max-width: 1200px) {
      display: none;
    }
    @media (max-width: 900px) {
      display: none;
    }
    @media (max-width: 605px) {
      display: none;
    }
  }

  .blue-cookie {
    position: absolute;
    right: 800px;
    bottom: 40px;
    z-index: 2;

    @media (max-width: 1200px) {
      display: none;
    }
    @media (max-width: 900px) {
      display: none;
    }
    @media (max-width: 605px) {
      display: none;
    }
  }

  .hero-skew {
    background: linear-gradient(
      180deg,
      rgba(249, 249, 249, 0.29) -14.99%,
      rgba(85, 202, 255, 0.52) 118.32%
    );
    width: 100% !important;
    height: 100% !important;
    transform: skewX(-25deg);
    position: absolute;
    top: 0;
    left: -20%;

    @media (max-width: 1200px) {
      transform: skewX(-0deg);
      transform: skewY(-20deg);
      left: 0%;
      top: -30%;
    }

    @media (max-width: 900px) {
      transform: skewX(-0deg);
      transform: skewY(-20deg);
      left: 0%;
      top: -25%;
    }

    @media (max-width: 600px) {
      transform: skewX(-0deg);
      transform: skewY(-20deg);
      left: 0%;
      top: -22%;
    }
  }

  .wrapper {
    width: 85%;
    margin: 0 auto;

    @media (max-width: 1387px) {
      width: 90%;
    }

    @media (max-width: 1315px) {
      width: 95%;
    }

    .container {
      display: flex;
      position: relative !important;
      width: 97.5vw !important;
      left: 0;
      top: 0;
      right: 0;
      height: 100%;
      padding-bottom: 50px;

      @media screen and (max-width: 1200px) {
        flex-direction: column;
        width: 100vw;
        transform: translateX(-50%);
        left: 50%;
        right: 50%;
        padding-bottom: 0px;
      }

      .left {
        width: 35%;
        padding-top: 100px;
        color: var(--head-txt);
        position: relative;

        h1 {
          font-size: 90px;
          font-style: normal;
          line-height: 94.5px;
          max-width: 600px;
          letter-spacing: 1px;
          font-weight: 700;

          @media (max-width: 1387px) {
            font-size: 80px;
            font-style: normal;
            line-height: 88px;
            max-width: 600px;
            letter-spacing: 1px;
            font-weight: 700;
          }

          @media screen and (max-width: 1200px) {
            max-width: 750px;
            font-size: 70px;
            font-style: normal;
            line-height: 80px;
            font-weight: 700;
          }

          @media screen and (max-width: 900px) {
            max-width: 750px;
            font-size: 70px;
            font-style: normal;
            line-height: 80px;
            font-weight: 700;
          }

          @media screen and (max-width: 700px) {
            max-width: 300px;
            font-size: 40px;
            font-style: normal;
            line-height: 50px;
            font-weight: 700;
          }
        }

        p {
          max-width: 500px;
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 26px;
          color: var(--text-sec);
          letter-spacing: 1px;
          margin: 20px 0 40px 0;
          opacity: 0.7;
        }

        @media screen and (max-width: 1200px) {
          padding-bottom: 0px;
          padding-top: 0px;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          width: 100%;
        }
      }

      .right {
        flex: 1;
        height: 100%;

        @media screen and (max-width: 1200px) {
          width: 100%;
        }

        img {
          width: 76%;
          height: 100%;

          @media (max-width: 1387px) {
            width: 96%;
          }

          @media (max-width: 1315px) {
            width: 100%;
          }

          @media screen and (max-width: 900px) {
            max-height: 400px;
          }
        }
      }
    }
  }
}
