.modal {
    display: block; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  }
  
  /* Modal Content */
  .modal-content {
    background-color: #fefefe;
    margin: 5% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 70%; /* Could be more or less, depending on screen size */
  }

  .modal-content > img {
    width: 100%;
  }
  
  /* Close Button */
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  
  @media screen and (max-width: 600px) {
    .modal {
        width: 100%;
    }
    .modal-content {
      margin: 20% auto; /* Adjust margin for smaller screens */
      /* padding: 10px; */
      width: 100%; /* Adjust width for smaller screens */
    }
    .modal-content > img {
        width: 100%;
    }
  }
  @media screen and (min-width: 601px) and (max-width: 1029px) {
    .modal {
        width: 100%;
    }
    .modal-content {
      margin: 10% auto; /* Centered horizontally and 10% from the top */
      width: 100%; /* Adjust width for tablets */
    }
    .modal-content > img {
        width: 100%;
    }
  }

  @media screen and (min-width:1030px) and (max-width:1280px) {
    .modal {
        width: 100%;
    }
    .modal-content {
      margin: 10% auto; /* Centered horizontally and 10% from the top */
      width: 70%; /* Adjust width for tablets */
    }
    .modal-content > img {
        width: 100%;
    }

  }